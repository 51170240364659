import React from "react";

export const CartIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.6845 15.1875H3.31641C3.17798 15.1875 3.04441 15.1365 2.94126 15.0441C2.83812 14.9518 2.77263 14.8247 2.75735 14.6871L1.75735 5.68712C1.74861 5.60849 1.75656 5.52891 1.78067 5.45356C1.80478 5.37822 1.84451 5.3088 1.89727 5.24986C1.95003 5.19091 2.01463 5.14375 2.08685 5.11147C2.15908 5.07919 2.2373 5.0625 2.31641 5.0625H15.6845C15.7636 5.0625 15.8418 5.07919 15.914 5.11147C15.9863 5.14375 16.0509 5.19091 16.1036 5.24986C16.1564 5.3088 16.1961 5.37822 16.2202 5.45356C16.2443 5.52891 16.2523 5.60849 16.2435 5.68712L15.2435 14.6871C15.2283 14.8247 15.1628 14.9518 15.0596 15.0441C14.9565 15.1365 14.8229 15.1875 14.6845 15.1875Z"
        stroke="#33251A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.1875 7.3125V5.0625C6.1875 4.31658 6.48382 3.60121 7.01126 3.07376C7.53871 2.54632 8.25408 2.25 9 2.25C9.74592 2.25 10.4613 2.54632 10.9887 3.07376C11.5162 3.60121 11.8125 4.31658 11.8125 5.0625V7.3125"
        stroke="#33251A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
