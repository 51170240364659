import React from "react";

export const AppIcon = () => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 27.000000 26.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,26.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none"
      >
        <path
          d="M42 149 l3 -92 69 -17 c38 -10 74 -16 81 -14 7 3 22 39 34 80 24 82
21 97 -24 108 -14 4 -25 11 -25 16 0 6 -31 10 -70 10 l-71 0 3 -91z m46 58 c2
-12 -3 -17 -17 -17 -15 0 -21 6 -21 21 0 25 33 22 38 -4z m82 3 c0 -11 -7 -20
-15 -20 -8 0 -15 9 -15 20 0 11 7 20 15 20 8 0 15 -9 15 -20z m56 -20 c8 -8 5
-32 -10 -80 -19 -67 -21 -70 -51 -69 -23 1 -25 3 -7 6 20 4 22 10 22 79 0 65
2 74 18 74 10 0 23 -5 28 -10z m-96 -25 c0 -8 -9 -15 -20 -15 -11 0 -20 7 -20
15 0 8 9 15 20 15 11 0 20 -7 20 -15z m-42 -38 c2 -12 -3 -17 -17 -17 -15 0
-21 6 -21 21 0 25 33 22 38 -4z m82 -2 c0 -8 -7 -15 -15 -15 -8 0 -15 7 -15
15 0 8 7 15 15 15 8 0 15 -7 15 -15z m-40 -40 c0 -8 -9 -15 -20 -15 -11 0 -20
7 -20 15 0 8 9 15 20 15 11 0 20 -7 20 -15z"
        />
      </g>
    </svg>
  );
};
