const Logo = ({ className = "", ...props }) => (
  <svg
    width="40"
    height="16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...props}
  >
    <path
      d="M39.7099 0.515654L39.6869 0.492634C39.5028 0.31768 39.2634 0.225599 39.0101 0.225599C38.7431 0.225599 38.4945 0.326888 38.3103 0.515654L31.1142 7.71179L26.372 2.96961C26.3398 2.93738 26.3076 2.90976 26.2707 2.88214C26.0866 2.70718 25.8471 2.6105 25.5893 2.6105C25.3223 2.6105 25.0737 2.71179 24.8895 2.90055L20.0414 7.75322L15.2993 3.01105C14.9171 2.62891 14.2956 2.62431 13.9088 2.99724L9.15285 7.75322L1.68969 0.290055C1.50552 0.101289 1.25691 0 0.989871 0C0.72744 0 0.478821 0.101289 0.290055 0.290055C0.101289 0.478821 0 0.72744 0 0.989871C0 1.25691 0.101289 1.50552 0.290055 1.68969L7.75322 9.15285L6.16943 10.7366C5.78269 11.1234 5.78269 11.7495 6.16943 12.1363C6.55617 12.523 7.18692 12.523 7.56906 12.1363L9.15285 10.5525L13.8122 15.2164C13.8168 15.221 13.826 15.2302 13.8306 15.2348C13.849 15.2578 13.872 15.2808 13.8904 15.2993C14.0792 15.488 14.3278 15.5893 14.5902 15.5893C14.8527 15.5893 15.1059 15.488 15.2901 15.2993L20.0368 10.5525L24.8389 15.3499C25.023 15.5341 25.2716 15.64 25.5387 15.64C25.7459 15.64 25.9392 15.5801 26.1096 15.4604C26.1924 15.4144 26.2615 15.3591 26.3306 15.2947L31.1142 10.5111L32.7256 12.1225C33.1123 12.5092 33.7431 12.5092 34.1252 12.1225C34.512 11.7357 34.512 11.1096 34.1252 10.7228L32.5138 9.11142L39.7099 1.91529C40.0967 1.53315 40.0967 0.902394 39.7099 0.515654ZM29.7099 9.11602L25.5571 13.2689L21.4411 9.15285L25.5939 5L29.7099 9.11602ZM18.6372 9.15285L14.5948 13.1952L10.5525 9.15285L14.5948 5.1105L18.6372 9.15285Z"
      fill="#563E2C"
    />
  </svg>
);

export default Logo;
