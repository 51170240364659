import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const registerApi = createApi({
  reducerPath: "registerApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://woolit.no/api/wds/interests/register",
  }),
  endpoints: (builder) => ({
    register: builder.mutation<void, { email: string; source: string }>({
      query: ({ email, source }) => ({
        url: ``,
        method: "POST",
        body: { email, source },
      }),
    }),
  }),
});

// Export hooks for usage in functional components
export const { useRegisterMutation } = registerApi;
