import React, { Fragment, PropsWithChildren } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Cross1Icon, MagnifyingGlassIcon } from "@radix-ui/react-icons";

type Props = {
  open: boolean;
  onClose: () => void;
};

const SidebarMenu: React.FC<PropsWithChildren<Props>> = ({
  children,
  open,
  onClose,
}) => {
  return (
    <Transition show={open} as={Fragment}>
      <Dialog
        open={open}
        onClose={onClose}
        className="fixed inset-0 z-50 overflow-y-auto"
        static
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-black/20 backdrop-blur-[0.8px]" />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="transition-transform ease-out duration-200"
          enterFrom="-translate-x-full"
          enterTo="translate-x-0"
          leave="transition-transform ease-in duration-200"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
        >
          <div className=" h-full w-full bg-[#faf6f2]">
            <div className=" h-fit w-full ">
              <button onClick={onClose} className="m-3 px-4 ">
                <Cross1Icon height={25} width={25} />
              </button>
            </div>
            <div className="flex flex-col gap-3 px-12 py-10">
              <a
                href="https://shop.woolit.no"
                className="block cursor-pointer text-lg md:hidden"
              >
                Designs
              </a>
              <a
                href="https://shop.woolit.no/product/yarn"
                className="block cursor-pointer text-lg md:hidden"
              >
                Yarn
              </a>
              <a
                href="https://shop.woolit.no/product/needle"
                className="block cursor-pointer text-lg md:hidden whitespace-nowrap"
              >
                Needles
              </a>
            </div>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition>
  );
};

export default SidebarMenu;
